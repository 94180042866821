import {effect, untracked} from '@preact/signals-react';
import {useSignals} from '@preact/signals-react/runtime';

export function reaction(deps, fn) {
  return effect(() => {
    const value = deps();
    untracked(() => fn(value));
  });
}

export function when(predicate: () => boolean) {
  let resolvePromise;
  let rejectPromise;
  const promise = new Promise((resolve, reject) => {
    resolvePromise = resolve;
    rejectPromise = reject;
  });

  const dispose = reaction(predicate, (value) => {
    if (value) {
      resolvePromise(value);
    }
  });
  return promise.finally(() => {
    dispose();
  });
}

// useful when rendering a list of signals to allow for updating individual signals without having to update the list reference, which would cause re-renders on all items
export function ComponentFromSignal({signal, Component, propsKey}) {
  useSignals();
  const {value} = signal;
  return <Component {...(propsKey ? value[propsKey] : value)} />;
}
