import type {Board, BoardId, BoardIdObj} from '@/model/board/types';
import type {OrgIdObj} from '@/model/org/types';
import {type Org, type OrgId} from '@/model/org/types';
import type {User} from '@/model/user/types';
import {EntityArraySignalsByIdStore, EntitySignalsByIdStore} from '@/state/stores';
import {when} from '@/utils/signals';
import type {Signal} from '@preact/signals-react';
import {signal} from '@preact/signals-react';

export const currentUserSignal = signal<User | null | undefined>(undefined);
export const userOrgsSignal = signal<Org[] | null | undefined>(undefined);
export const boardsByOrgIdSignal = signal<Record<OrgId, Signal<Signal<Board>[]>>>({});
export const boardSignalsById: Record<BoardId, Signal<Board | null | undefined>> = {};
export const orgSignalsById: Record<OrgId, Signal<Org | null | undefined>> = {};

export const orgSignalsByIdStore = new EntitySignalsByIdStore<OrgIdObj, Org>();
export const boardSignalsByIdStore = new EntitySignalsByIdStore<BoardIdObj, Board>();
export const boardsSignalsByOrgIdStore = new EntityArraySignalsByIdStore<
  OrgIdObj,
  BoardIdObj,
  Board
>(boardSignalsByIdStore);

export const GetCurrentUser = async () => {
  await when(() => currentUserSignal.value !== undefined);
  return currentUserSignal.value as User | null;
};
